import { App, Form, Input, Modal } from 'antd';
import { useCallback, useEffect } from 'react';
import { useSendNotificationsMutation } from '../../users/usersApi';
import { SendNotificationsModel } from 'types/SendNotificationsModel';
import { INotification } from '../types';
import { useUpdateNotificationMutation } from '../notifications.api';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  model?: INotification | null;
}

export function NotificationModal({ isOpen, onCancel, model }: Props) {
  //* APIs
  const [send, { isLoading: sendLoading }] = useSendNotificationsMutation();
  const [update, { isLoading: updateLoading }] =
    useUpdateNotificationMutation();

  const isLoading = sendLoading || updateLoading;

  //* Hooks
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  //* Handlers
  const onSuccess = useCallback(
    (message: string) => {
      notification.success({
        message,
      });
      onCancel();
    },
    [notification, onCancel]
  );

  const onFinish = useCallback(
    (values: SendNotificationsModel) => {
      if (model) {
        update({ ...values, id: model.id })
          .unwrap()
          .then(({ message }) => {
            onSuccess(message);
          });
      } else {
        send(values)
          .unwrap()
          .then(({ message }) => {
            onSuccess(message);
          });
      }
    },
    [send, model, update, onSuccess]
  );

  //* React Hooks
  useEffect(() => {
    form.resetFields(['title', 'body']);

    if (model) {
      form.setFields([
        { name: 'title', value: model.title },
        { name: 'body', value: model.body },
      ]);
    }
  }, [model, form]);

  return (
    <Modal
      okButtonProps={{ htmlType: 'submit' }}
      forceRender={true}
      confirmLoading={isLoading}
      title={model ? 'تعديل بيانات إشعار' : 'إرسال إشعارات'}
      open={isOpen}
      width={768}
      onCancel={onCancel}
      modalRender={(modal) => (
        <Form
          initialValues={{
            title: '',
            body: '',
          }}
          form={form}
          onFinish={onFinish}
          disabled={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name='notification-form'
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item name='title' label='عنوان الرسالة'>
        <Input />
      </Form.Item>
      <Form.Item name='body' label='محتوى الرسالة'>
        <Input.TextArea rows={10} />
      </Form.Item>
    </Modal>
  );
}
