import { apiSlice } from 'features/api/apiSlice';
import { INotification } from './types';
import { ResponseWithMessage } from 'common/interfaces';
import { SendNotificationsModel } from 'types/SendNotificationsModel';

const notificationsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<INotification[], void>({
      query: () => '/Auth/GetTopTenNotifications',
      transformResponse(query: { notifications: INotification[] }) {
        return query.notifications;
      },
      providesTags: ['Notifications'],
    }),
    removeNotification: builder.mutation<ResponseWithMessage, number>({
      query: (id) => ({
        url: 'Auth/DeleteNotification',
        params: { id },
        method: 'GET',
      }),
      invalidatesTags: ['Notifications'],
    }),
    updateNotification: builder.mutation<
      ResponseWithMessage,
      SendNotificationsModel & { id: number }
    >({
      query: (body) => ({
        url: `Auth/EditNotification`,
        body,
        params: { id: body.id },
        method: 'POST',
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useRemoveNotificationMutation,
  useUpdateNotificationMutation,
} = notificationsAPI;
