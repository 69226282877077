import { Form, Input, Modal, App } from 'antd';
import { useCallback, useState } from 'react';
import { useRejectServiceRequestMutation } from './usersRequestsAPI';

type Props = { onCancel: () => void; id: number };

export default function RejectModal({ onCancel, id }: Props) {
  // ** API
  const [reject] = useRejectServiceRequestMutation();

  // ** State
  const [loading, setLoading] = useState(false);

  // ** Hooks
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  // ** handlers
  const onFinishForm = useCallback(
    ({ rejectReason }: { rejectReason: string }) => {
      setLoading(true);
      reject({ id, rejectReason })
        .unwrap()
        .then(() => {
          notification.warning({ message: 'تم رفض الطلب!' });
          onCancel();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [reject, id, onCancel, notification]
  );

  return (
    <Modal
      open
      title='رفض الطلب'
      onCancel={onCancel}
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <Form
        disabled={loading}
        name='service-request-pricing-form'
        form={form}
        onFinish={onFinishForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item name='rejectReason' label='سبب الرفض'>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
