import { Button } from 'antd';
import { useCallback, useState } from 'react';
import UserEditModal from './UserEditModal';
import ResetPasswordModal from './ResetPasswordModal';
import AddUserModal from './AddUserModal';
import { IUsersView, User } from 'common/interfaces/interfaces';
import BulkEmailModal from './BulkEmailModal';
import { UsersTable } from './UsersTable';

export default function Users() {
  // ** State
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [state, setState] = useState<IUsersView>({
    modalView: undefined,
    user: null,
  });

  const { user, modalView } = state;

  const closeModal = useCallback(
    () => setState((prev) => ({ ...prev, modalView: undefined })),
    []
  );

  const handleOnClickAddButton = useCallback(() => {
    setState((prev) => ({ ...prev, modalView: 'add' }));
  }, []);

  return (
    <>
      <div className='flex items-center gap-1.5 mb-5'>
        <Button onClick={handleOnClickAddButton} type='primary'>
          اضافة مستخدم جديد
        </Button>
        <Button
          disabled={!selectedUsers.length}
          onClick={setState.bind(null, { modalView: 'bulkEmail', user: null })}
        >
          إرسال بريد
        </Button>
      </div>

      <UsersTable setState={setState} setSelectedUsers={setSelectedUsers} />

      <AddUserModal isOpen={modalView === 'add'} closeModal={closeModal} />
      {user && (
        <>
          <UserEditModal
            user={user!}
            isOpen={modalView === 'edit'}
            closeModal={closeModal}
          />
          <ResetPasswordModal
            userId={user.id}
            isOpen={modalView === 'resetPassword'}
            closeModal={closeModal}
          />
        </>
      )}
      <BulkEmailModal
        onCancel={closeModal}
        selectedUsers={selectedUsers.map(({ id }) => id)}
        isOpen={modalView === 'bulkEmail'}
      />
    </>
  );
}
