import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/store';

export const TagTypes = [
  'User',
  'More',
  'ContactUs',
  'ConditionsAndTerms',
  'Institutes',
  'Visas',
  'Packages',
  'Services',
  'ServiceRequests',
  'Backups',
  'Group',
  'SubGroups',
  'Home',
  'Notifications',
] as const;

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth?.token;
      if (token) headers.set('Authorization', `Bearer ${token}`);
      headers.set('Accept-Language', 'ar');
      return headers;
    },
  }),
  tagTypes: TagTypes,
  endpoints: () => ({}),
});
