import { AppRoute } from './interfaces/interfaces';
import {
  CheckSquareOutlined,
  IdcardOutlined,
  HomeOutlined,
  MessageOutlined,
  SmallDashOutlined,
  BuildOutlined,
  UserSwitchOutlined,
  ControlOutlined,
  GroupOutlined,
  UnorderedListOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import Users from 'features/users/UsersView';
import ConditionsAndTermsView from 'features/conditions-and-terms/ConditionsAndTermsView';
import MoreView from 'features/more/MoreView';
import ContactMessagesView from 'features/contact-messages/ContactMessagesView';
import InstitutesView from 'features/institutes/InstitutesView';
import { FaPassport, FaPlane, FaUniversity } from 'react-icons/fa';
import VisasView from 'features/visas/VisasView';
import TourismPackagesView from 'features/tourism-packages/TourismPackagesView';
import ServicesView from 'features/services/ServicesView';
import UsersRequestsView from 'features/users-requests/UsersRequestsView';
import SystemManagementView from 'features/system-management/views/SystemManagementView';
import { GroupsTableView } from 'features/groups';
import { SubGroupsTableView } from 'features/sub-groups';
import { HomeManagement } from 'features/home';
import { NotificationsPage } from 'features/notifications';

const RoutesList: AppRoute[] = [
  {
    key: '/',
    label: 'الرئيسية',
    icon: <HomeOutlined />,
    element: <HomeManagement />,
  },
  {
    label: 'الإشعارات',
    icon: <NotificationOutlined />,
    key: '/notifications',
    element: <NotificationsPage />,
  },
  {
    label: 'القوائم الرئيسية',
    icon: <GroupOutlined />,
    key: '/groups',
    element: <GroupsTableView />,
  },
  {
    label: 'القوائم الفرعية',
    icon: <UnorderedListOutlined />,
    key: '/sub-groups',
    element: <SubGroupsTableView />,
  },
  {
    label: 'طلبات العملاء',
    icon: <UserSwitchOutlined />,
    key: '/users-requests',
    element: <UsersRequestsView />,
  },
  {
    key: '/contact-messages',
    label: 'رسائل التواصل',
    icon: <MessageOutlined />,
    element: <ContactMessagesView />,
  },
  {
    label: 'المستخدمين والصلاحيات',
    icon: <IdcardOutlined />,
    key: '/users',
    element: <Users />,
  },

  {
    label: 'بيانات التأشيرات',
    icon: <FaPassport />,
    key: '/visas',
    element: <VisasView />,
  },
  {
    label: 'المعاهد والجامعات',
    icon: <FaUniversity />,
    key: '/institutes',
    element: <InstitutesView />,
  },

  {
    label: 'الباكدجات السياحية',
    icon: <FaPlane />,
    key: '/tourism-packages',
    element: <TourismPackagesView />,
  },
  {
    label: 'الخدمات',
    icon: <BuildOutlined />,
    key: '/services',
    element: <ServicesView />,
  },
  {
    label: 'صفحة المزيد',
    icon: <SmallDashOutlined />,
    key: '/more',
    element: <MoreView />,
  },
  {
    key: '/conditions-and-terms',
    label: 'الشروط والاحكام',
    icon: <CheckSquareOutlined />,
    element: <ConditionsAndTermsView />,
  },
  {
    key: '/system-management',
    label: 'إدارة قواعد البيانات',
    icon: <ControlOutlined />,
    element: <SystemManagementView />,
  },
];

export default RoutesList;
