import { Button, Popconfirm, Table } from 'antd';
import {
  useGetNotificationsQuery,
  useRemoveNotificationMutation,
} from '../notifications.api';
import { useCallback, useState } from 'react';
import { NotificationModal } from '../components';
import { INotification } from '../types';

export function NotificationsPage() {
  //State
  const [isModalOpen, setModalOpen] = useState(false);
  const [modelToUpdate, setModelToUpdate] = useState<INotification | null>(
    null
  );

  // APIs
  const { data, isLoading } = useGetNotificationsQuery();
  const [removeNotification] = useRemoveNotificationMutation();

  // Handlers
  const onConfirmRemove = useCallback(
    (id: number) => {
      removeNotification(id);
    },
    [removeNotification]
  );

  const closeModals = useCallback(() => {
    setModalOpen(false);
    setModelToUpdate(null);
  }, []);

  return (
    <>
      <div className='mb-5'>
        <Button onClick={() => setModalOpen(true)}>إرسال إشعارات</Button>
      </div>
      <Table
        scroll={{ x: 'max-content' }}
        loading={isLoading}
        bordered
        rowKey='id'
        dataSource={data}
        columns={[
          {
            title: 'م.',
            width: 16,
            render: (_: any, __: any, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: 'العنوان',
            width: 36,
            render: (_, { title }) => {
              return title;
            },
          },
          {
            title: 'المحتوى',
            width: 20,
            render: (_, { body }) => {
              return body;
            },
          },
          {
            title: 'التوقيت',
            width: 10,
            render: (_, { createdAt }) => {
              return createdAt;
            },
          },
          {
            title: '',
            key: 'actions',
            width: 20,
            render: (_, model) => (
              <>
                <Button
                  type='dashed'
                  onClick={() => {
                    setModelToUpdate(model);
                    setModalOpen(true);
                  }}
                >
                  تعديل
                </Button>
                <Popconfirm
                  title='تأكيد الحذف'
                  onConfirm={() => onConfirmRemove(model.id)}
                  okButtonProps={{ danger: true }}
                >
                  <Button type='dashed' danger>
                    حذف
                  </Button>
                </Popconfirm>
              </>
            ),
          },
        ]}
        pagination={false}
      />
      <NotificationModal
        onCancel={closeModals}
        isOpen={isModalOpen}
        model={modelToUpdate}
      />
    </>
  );
}
